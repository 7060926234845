import { createRouter, createWebHistory } from 'vue-router'
import store from "../store";
import Home from "../views/VHome.vue";
import Download from "../views/VDownload.vue";
import LogIn from "../views/VLogin.vue";
import ForgotPwd from "../views/VForgotPwd.vue";
import ForgotPwdMail from "../views/VForgotPwdMail.vue";
import ResetPassword from "../views/VResetPassword.vue";
import SignUp from "../views/VSignup.vue";
import SignUpMail from "../views/VSignupMail.vue";
import VerifiyAccount from "../views/VerifiyAccount.vue";
import User from "../views/VUser.vue";
import Support from "../views/VSupport.vue";
import Pricing from "../views/VPricing.vue";
import Datasets from '../views/VDatasets.vue'
import DataChart from '../views/VDataChart.vue'
import Dashboards from '../views/VDashboards.vue'
import MyChat from '../views/VMyChat.vue'
import Search from '../views/VSearch.vue'
import CountryMap from '../views/VCountryMap.vue'
import CountryProfile from '../views/VCountryProfile.vue'
import CountryIndicator from '../views/VCountryIndicator.vue'
import GeopoRisk from '../views/VGeopoRisk.vue'
import MonitorRisk from '../views/VMonitorRisk.vue'
import DataLab from '../views/VDataLab.vue'
// import VPublications from '../views/VPublications.vue';
import Apidoc from "@/views/VApidoc.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/download',
    name: 'Download',
    component: Download,
  },
  {
    path: "/login",
    name: "LogIn",
    component: LogIn,
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/signupmail",
    name: "SignUpMail",
    component: SignUpMail,
  },
  {
    path: "/verifyaccount",
    name: "VerifiyAccount",
    component: VerifiyAccount,
  },
  {
    path: "/forgotpwd",
    name: "ForgotPwd",
    component: ForgotPwd,
  },
  {
    path: "/forgotpwdmail",
    name: "ForgotPwdMail",
    component: ForgotPwdMail,
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: '/support',
    name: 'support',
    component: Support
  },
  {
    path: "/pricing",
    name: "pricing",
    component: Pricing,
  },
  {
    path: "/datasets",
    name: "DataSets",
    component: Datasets,
    meta: { requiresAuth: true }
  },
  {
    path: '/search',
    name: 'search',
    component: Search,
  },
  {
    path: '/user',
    name: 'user',
    component: User,
    meta: { requiresAuth: true }
  },
  {
    path: '/data/:dataset/:symbol',
    name: 'symbol',
    component: DataChart,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/data/:dataset/:symbol/:params',
    name: 'symbolwithparams',
    component: DataChart,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/dashboards/:id?',
    name: 'dashboards',
    component: Dashboards,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/chat',
    name: 'Mychat',
    component: MyChat,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/appESG',
    name: 'CountryMap',
    component: CountryMap,
  },
  {
    path: '/countryProfile/:country',
    name: 'Profile',
    component: CountryProfile,
  },
  {
    path: '/countryIndicator',
    name: 'Indicator',
    component: CountryIndicator,
  },
  {
    path: '/products/GeopoliticalRisk',
    name: 'GeopoRisk',
    component: GeopoRisk,
  },
  {
    path: '/products/RiskMonitor',
    name: 'MonitorRisk',
    component: MonitorRisk,
  },
  {
    path: '/dataLab',
    name: 'DataLab',
    component: DataLab,
  },
  {
    path: '/apidoc',
    name: 'Apidoc',
    component: Apidoc,
  },
  // {
  //   path: '/publications',
  //   name: 'Publications',
  //   component: VPublications,
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router
