<template>
  <div class="home_page content text-center bg-grey" style="padding-bottom: 1rem;">
    <particles />
    <div class="header-container">
      <div>
        <h1 class="text-white header-title">TAC Economics ClientGate</h1>
        <div class="d-flex justify-content-center">
          <div v-if="!isLoggedIn" class="btn-container mt-5">
            <router-link to="/login" class="me-3 text-light h5 btn-login" @click="closeMenu">Login</router-link>
          </div>
    
          <div v-if="!isLoggedIn" class="nav-item me-3 mt-5">
            <router-link to="/signup" class="me-3 h5 btn-signup" @click="closeMenu">Signup</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="content-container">
      <div class="image-container">
        <img src="../../public/img/banner_image/Menu_RiskMonitor.png" alt="banner" class="banner-image">
      </div>
      <div class="text-container">
        <h1>Risk Monitor</h1>
        <p>RiskMonitor is an interactive online quantitative tool designed to provide a comprehensive overview of country risk 
          through multiple measures: <strong>Economic & Financial Risk, Political & Governance Risk</strong>
        and the <strong>Country Risk Premium</strong> (RRECC). Updated monthly, it covers 100 emerging markets, enabling users to make
        informed decisions on risk exposure.</p>
      </div>
    </div>
    <hr class="divider"/>
    <div class="content-container">
      <div class="text-left-container">
        <h1>Geopolitical Risk</h1>
        <p>The Geopolitical Risk tool provides a comprehensive and timely overview of the geopolitical risks and emerging threats. 
          It includes two components: (1) <strong>GPSI (Geopolitical Sensitivity Index)</strong>, which evaluates a country's 
          vulnerability to geopolitical risks based on alliances, conflicts, and critical resources; and (2) 
          <strong>Alert GPT (Geopolitical Tensions Alert)</strong>, which monitors real-time geopolitical tensions through media 
          analysis, tracking coverage volume, negative sentiment, and violent language.</p>
      </div>
      <div class="image-container">
        <img src="../../public/img/banner_image/Menu_Geopolitical_Risk.png" alt="banner" class="banner-image">
      </div>
    </div>
    <hr class="divider"/>
    <div class="content-container">
      <div class="image-container">
        <img src="../../public/img/banner_image/Datalab.png" alt="banner" class="banner-image">
      </div>
      <div class="text-container">
        <h1>Datalab</h1>
        <p>
          TAC ECONOMICS Datalab is an aggregator of macroeconomic, financial, and sectorial data with global coverage, accessible via a secure API or Add-in Excel, updated daily. 
          The Datalab also integrates the results of risk ratings, warning signals, and forecasts from TAC ECONOMICS AI models.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import particles from "../components/particles.vue";

export default {
  components: {
    particles
  },
  computed: {
    ...mapGetters(["isLoggedIn"])
  },
  methods: {
    async signup() {
      this.$router.push('/signup')
    },
  }
}
</script>

<style scoped>
.btn-login {
  border-radius: 20px;
  padding: 0.4em 1.5em; 
  border: solid 2px white;
}

.btn-signup {
  border-radius: 20px;
  padding: 0.4em 1.5em;
  background-color: white;
  border: solid 2px white;
  color: #021A6F;
  font-weight: 600;
  font-family: 'Poppins';
  cursor: pointer;
  z-index: 1000000;
}
.home_page {
  background-color: #021A6F;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25rem;
}

.header-title {
  font-size: 5rem;
  font-weight: normal;
}

.content-container {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.image-container {
  width: 50%;
  height: 300px;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-container {
  width: 50%;
  text-align: left;
  align-items: flex-start;
  margin-left: 2rem;
  margin-right: 1rem;
  color: white;
  font-size: 1.2rem;
  height: 300px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-left-container {
  width: 50%;
  text-align: left;
  align-items: flex-start;
  margin-right: 2rem;
  margin-left: 1rem;
  color: white;
  font-size: 1.2rem;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-container h1, .text-left-container h1 {
  color: white;
}

.divider {
  color: white;
  margin: 2rem;
}

@media (max-width: 768px) {
  .header-title {
    font-size: 3rem;
  }

  .content-container {
    flex-direction: column;
  }

  .image-container {
    width: 100%;
    height: 200px;
  }

  .text-container {
    width: 100%;
    margin-left: 3rem;
    margin-right: 1rem;
    height: 200px;
    font-size: 1rem;
  }

  .text-container h1 {
    font-size: 1.5rem;
  }

  .text-left-container h1 {
    font-size: 1.5rem;
  }

  .text-left-container {
    width: 100%;
    margin-left: 3rem;
    margin-right: 1rem;
    height: 250px;
    font-size: 1rem;
  }

  .banner-image {
    height: 100%;
  }

  .image-container {
      display: none;
  }
}

@media (max-width: 550px) {
  .header-title {
    font-size: 2rem;
  }

  .text-container {
    margin-left: 2rem;
    margin-right: 1rem;
    font-size: 0.8rem;
  }

  .text-container h1 {
    font-size: 1.5rem;
  }

  .text-left-container h1 {
    font-size: 1.5rem;
  }

  .text-left-container {
    margin-left: 2rem;
    margin-right: 1rem;
    font-size: 0.8rem;
  }
}

</style>
